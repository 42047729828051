import request from '@/utils/request'

// 门店付款方式限购列表
export function listPaymodeLimit (query) {
  return request({
    url: '/api/system/shop/pay/limit/list',
    method: 'get',
    params: query
  })
}

// 新增门店付款方式限购
export function savePaymodeLimit (data) {
  return request({
    url: '/api/system/shop/pay/limit/saveOrUpdate',
    method: 'POST',
    data
  })
}


// 删除门店付款方式限购
export function delPaymodeLimit (lineIds) {
  return request({
    url: '/api/system/shop/pay/limit/remove',
    method: 'DELETE',
    data: lineIds
  })
}