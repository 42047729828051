import request from '@/utils/request'

//优惠券

//新增获取单据号
export function getBillNo () {
  return request({
    url: `/api/system/marketing/base/coupon/case/getCouponCaseNo`,
    method: 'get'
  })
}
// 优惠券列表
export function CouponList(query) {
    return request({
      url: '/api/system/marketing/base/coupon/case/list',
      params:query
    })
}

// 新增优惠券
export function saveCoupon(data) {
    return request({
      url: '/api/system/marketing/base/coupon/case/save',
      method:'post',
      data
    })
}

// 编辑优惠券
export function updateCoupon(data) {
  return request({
    url: '/api/system/marketing/base/coupon/case/update',
    method:'put',
    data
  })
}

// 是否启用/停用优惠券
export function isStopCoupon(data) {
  return request({
    url: '/api/system/marketing/base/coupon/case/isStop',
    method:'put',
    data
  })
}

// 删除优惠券
export function deleteCoupon(couponCaseIds) {
  return request({
    url: '/api/system/marketing/base/coupon/case/delete',
    method:'put',
    data:{
      couponCaseIds
    }
  })
}

// 复制优惠券
export function copyCoupon(couponCaseIds) {
  return request({
    url: '/api/system/marketing/base/coupon/case/copy',
    method:'post',
    data:{
      couponCaseIds
    }
  })
}

// 优惠券详情
export function couponDetail(couponCaseId) {
  return request({
    url: `/api/system/marketing/base/coupon/case/getDetail?couponCaseId=${couponCaseId}`
  })
}

// 设置充值券卡密
export function setCouponPwdLen(data) {
  return request({
    url: '/api/system/marketing/base/coupon/case/couponPwdLen',
    method:'post',
    data
  })
}

//促销方案


// 促销方案列表
export function PromoList(query) {
  return request({
    url: '/api/system/marketing/base/promo/case/list',
    params:query
  })
}

// 新增促销方案
export function savePromo(data) {
  return request({
    url: '/api/system/marketing/base/promo/case/save',
    method:'post',
    data
  })
}

// 促销方案详情
export function promoDetail(billId) {
  return request({
    url: `/api/system/marketing/base/promo/case/getDetail?billId=${billId}`
  })
}


// 编辑优惠券
export function updatePromo(data) {
  return request({
    url: '/api/system/marketing/base/promo/case/update',
    method:'put',
    data
  })
}

// 是否启用/停用促销方案
export function isStopPromo(data) {
  return request({
    url: '/api/system/marketing/base/promo/case/isStop',
    method:'put',
    data
  })
}

// 复制促销方案
export function copyPromo(billIds) {
  return request({
    url: '/api/system/marketing/base/promo/case/copy',
    method:'post',
    data:{
      billIds
    }
  })
}

// 更新促销方案状态
export function updateStatusPromo(data) {
  return request({
    url: '/api/system/marketing/base/promo/case/updateStatus',
    method:'post',
    data
  })
}

// 设置券编码规则
export function setCouponNoRule(data) {
  return request({
    url: '/api/system/marketing/base/coupon/case/couponNoRule',
    method:'post',
    data
  })
}


// 获取新增券明细详细信息
export function getAddMarketingCouponDetail (couponCaseId) {
  return request({
    url: '/api/system/marketing/base/coupon/case/getAddMarketingCouponDetail?couponCaseId=' + couponCaseId,
    method: 'get'
  })
}


// 新增券明细详细信息
export function addMarketingCouponLog(data) {
  return request({
    url: '/api/system/marketing/base/coupon/case/addMarketingCouponLog',
    method:'post',
    data
  })
}


// 查看纸质明细列表
export function getPaperMarketingCouponBookList(query) {
  return request({
    url: '/api/system/marketing/base/coupon/case/getPaperMarketingCouponBookList',
    params:query
  })
}
